import React from 'react';
import Component from './components/Component';
import { VisitorIdentification, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { TweenMax } from 'gsap';
import Helmet from 'react-helmet';

import { hasProp } from './assets/utilities';
import { breakpoints } from './assets/settings';
// import { exit, enter } from './animations/page-transition';
import { transitionAnimation } from './animations/page-transition';

// data maps
import pageTypes from './maps/page-type';
import metaData from './maps/meta-data';
import MobileContext from './context/mobile';

// components
import NavMain from './components/_internal/NavMain';
import MobileMenu from './components/_internal/MobileMenu';
import PageContent from './components/_internal/PageContent';
import ErrorBoundary from './components/_internal/ErrorBoundary';

// assets
import './assets/normalize.css';

let hyperform = null;

if (typeof window !== 'undefined') {
  hyperform = require('hyperform');
  require('intersection-observer');

  if (window.outdatedBrowser) {
    throw new Error('Outdated Browser');
  }
}

class Layout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      route: this.props.route,
      transitionComplete: false,
      mobileMenuOpen: false,
      contactPanelOpen: false,
      isMobile: false,
      exitType: null
    }
  }

  toggleContactPanelOpen = (bool = false) => {
    this.setState({ contactPanelOpen: bool });
  }

  toggleMobileMenu = (bool = false) => {
    return this.setState({ mobileMenuOpen: bool }, () => {
      // prevent body scrolling when mobile menu is open
      document.body.style.overflow = this.state.mobileMenuOpen ? 'hidden' : 'auto';
    });
  }

  setTransitionComplete = (bool = false, pageType) => {
    this.toggleContactPanelOpen(false);
    const returnObj = {
      transitionComplete: bool
    };

    if (pageType) {
      returnObj.exitType = pageType.value;
    }

    this.setState(returnObj);
  }

  exitTransitionComplete = (newProps) => {
    this.scrollWindowTop();
    this.setState({
      route: newProps.route
    });
  }

  handleEnter = (node, mask) => {
    const { pageType } = this.props.route.fields,
          pageTypeData = pageTypes(hasProp(pageType));

    // remove any classes from body
    document.body.className = '';

    // entering
    transitionAnimation({
      type: 'enter',
      node,
      mask,
      animation: pageTypeData.animation,
      exitType: this.state.exitType,
      isMobile: this.state.isMobile,
      completeFn: () => this.setTransitionComplete(true, pageType)
    });
  }

  scrollWindowTop = () => {
    this.window.scrollTo(0, 0);
  }

  setIsMobile = () => {
    this.setState({
      isMobile: this.window.innerWidth < breakpoints.desktop
    });
  }

  componentDidMount() {

    if (hyperform) {
      hyperform.default(this.window);
    }

    this.scrollWindowTop();
    this.setIsMobile();
    this.window.addEventListener('resize', this.setIsMobile);

    // prevents Flash of Unstyled Content
    const pageContainer = document.querySelector('.react-container');

    if (pageContainer) {
      TweenMax.to(pageContainer, 0, { autoAlpha: 1 });
    }
  }

  componentWillUnmount() {
    this.window.removeEventListener('resize', this.setMobile);
  }

  componentWillReceiveProps(nextProps) {
    const { pageType } = this.props.route.fields,
          pageTypeData = pageTypes(hasProp(pageType) && pageType.value);

    // ensures that transition exit animations only fire once
    if (nextProps.route !== this.state.route) {
      this.setTransitionComplete(false, null);

      // exiting
      transitionAnimation({
        type: 'exit',
        enterType: pageType,
        animation: pageTypeData.animation,
        exitType: this.state.exitType,
        isMobile: this.state.isMobile,
        completeFn: () => this.exitTransitionComplete(nextProps)
      });
    }
  }

  render() {

    const { route } = this.props,
          { fields } = route,
          {
            pageType,
            openGraphImage,
            pageDescription,
            pageTitle,
            hideFromSearchEngines,
            nofollowLinks
          } = fields,
          pageTypeData = pageTypes(hasProp(pageType));
		  
	  
    const doesPageHaveMetaTitle = hasProp(pageTitle) !== false;
    const defaultTitleSuffix = 'Interactive Strategies - Washington, DC';
    
    // Currently used for job detail pages in which the title is fetched from the Workable API on the server side
    const contextAwarePageTitle = this.props.sitecoreContext.pageTitle;

    let title;
    
    if (contextAwarePageTitle) {
      title = `${contextAwarePageTitle} | ${defaultTitleSuffix}`;
    } else if (doesPageHaveMetaTitle) {
      title = `${hasProp(pageTitle)} | ${defaultTitleSuffix}`;
    } else if (route.name === 'home') {
      title = `${hasProp(pageTitle)}`;
    } else {
      title = `${defaultTitleSuffix}`;
    }
        
    const metaDesc = hasProp(pageDescription) || metaData('description');

    // Noindex all sites that aren't the final canonical URL: https://www.interactivestrategies.com/
    let robots = "";
    const isFinalCanonicalSchemeAndSubdomain = this.props.sitecoreContext.targetHostnameWithScheme.startsWith("https://www.");
    if(!isFinalCanonicalSchemeAndSubdomain){
      robots = "noindex, nofollow";
    } else {
      robots = `${hasProp(hideFromSearchEngines) ? "noindex" :"index"}, ${hasProp(nofollowLinks) ? "nofollow" : "follow"}`;
    }

    // OG image should include the full URL, so grab the protocol and host name first
    let ogImageUrl = this.props.sitecoreContext.targetHostnameWithScheme;
    let ogImageWidth = "";
    let ogImageHeight = "";

    if (hasProp(openGraphImage)){
      // Handle case where Sitecore link options can be changed to include or exclude the server URL
      const isImgSrcUrlFullyQualified = openGraphImage.value.src.startsWith("http");
      ogImageUrl = isImgSrcUrlFullyQualified ? openGraphImage.value.src : ogImageUrl + openGraphImage.value.src;

      ogImageWidth = openGraphImage.value.width;
      ogImageHeight = openGraphImage.value.height;
    } else {
      // Use the default OG image
      ogImageUrl += "/og-image.jpg";
      ogImageWidth = "1200";
      ogImageHeight = "630";
    }

    return (
      <React.Fragment>
        <ErrorBoundary>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={metaDesc} />
            <meta name="robots" content={robots}/>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={metaDesc} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={this.props.sitecoreContext.canonicalUrl} />
            <meta property="og:image" content={ogImageUrl} />
            <meta property="og:image:width" content={ogImageWidth} />
            <meta property="og:image:height" content={ogImageHeight} />
          </Helmet>

          <VisitorIdentification />

          <div className="react-container" style={styles.reactContainer}>
            <MobileContext.Provider value={this.state.isMobile}>

              <NavMain
                darkLinkText={this.state.contactPanelOpen}
                darkLogoText={pageTypeData.darkLogoText || false}
                navShadow={pageTypeData.navShadow || false}
                transitionComplete={this.state.transitionComplete}
                toggleMobileMenu={this.toggleMobileMenu}
              />

              <MobileMenu
                isOpen={this.state.mobileMenuOpen}
                toggleMobileMenu={this.toggleMobileMenu}
              />

              <PageContent
                route={this.state.route || this.props.route}
                onEnter={this.handleEnter}
                transitionComplete={this.state.transitionComplete}
                toggleMobileMenu={this.toggleMobileMenu}
                toggleContactPanelOpen={this.toggleContactPanelOpen}
              />
              
            </MobileContext.Provider>
          </div>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

const styles = {

  reactContainer: {
    visibility: 'hidden',
    position: 'relative'
  }
}

export default withSitecoreContext()(Layout);
